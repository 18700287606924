/* Default floating button styles */
.floating-button {
    position: fixed;
    width: 60px;
    height: 60px;
    background-color: #ff9800;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    cursor: grab;
    z-index: 1;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    animation: pulse 1.5s infinite;
    user-select: none;
    font-size: 34px;

    /* Positioning for larger screens (default: bottom-right) */
    bottom: 20px;
    right: 10px;
}

/* Active Dragging */
.floating-button:active {
    cursor: grabbing;
}

/* Pulsing animation */
@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    }
    50% {
        transform: scale(1.15);
        box-shadow: 0px 6px 15px rgba(255, 152, 0, 0.5);
    }
    100% {
        transform: scale(1);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    }
}

/* 📌 Correct position for Mobile and Tablet Devices */
@media screen and (max-width: 768px) {
    .floating-button {
        top: 50% ; /* Move button to vertical center */
        transform: translateY(-50%); /* Adjust for perfect centering */
        right: 6px; /* Keep it 6px from the right edge */
        bottom: unset; /* Remove bottom positioning */
        width: 45px; /* Smaller button size */
        height: 45px;
        font-size: 24px; /* Reduce icon size */
    }
}

/* Further reduce size for very small devices (phones) */
@media screen and (max-width: 480px) {
    .floating-button {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }
}