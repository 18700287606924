.settings_bg{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-bg);
    z-index: 2000;
}

.settings{
    position: fixed;
    width: 50vw;
    height: 100%;
    top: 1rem;
    left: 50%;
    transform: translate(-50%);
    flex-direction: column;
    background-color: var(--color-bg);
    padding: 20px;
    z-index: 2000;
}


.settings-close-icon{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.settings-close-icon svg{
    cursor: pointer;
    color: var(--color-text);
    width: 5vw;
    height: 5vh;
}

.dark-theme{
    display: flex;
    flex-direction: row;
    margin: 1rem;
    padding: 2rem;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-border);
}

.contrast-mode{
    display: flex;
    flex-direction: row;
    margin: 1rem;
    padding: 2rem;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-border);
}

.contact{
    display: flex;
    flex-direction: row;
    margin: 1rem;
    padding: 2rem;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-border);
}
.add-word{
    display: flex;
    flex-direction: row;
    margin: 1rem;
    padding: 2rem;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-border);
}

.settings-body p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 22px;
    color: var(--color-text);
    text-align: center;
    justify-content: center;
    white-space: nowrap;
}
.settings-body h1 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 32px;
    color: var(--color-text);
    justify-content: center;
    text-align: center;
    white-space: nowrap;
}

a:link, a:visited {
    background-color: var(--color-bg);
    color: var(--color-text);
    border: 2px solid var(--color-blue);
    font-family: var(--font-family);
    font-size: 22px;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
  }

  a:hover, a:active {
    background-color: var(--color-blue);
    color: var(--color-text-white);
  }

  .settings-footer {
    position: absolute;
    left: 50%;
    margin-bottom: 20px;
    transform: translate(-50%);
  }
.settings-footer h4 {
    font-family: var(--font-family);
    color: var(--color-subtext);
    font-size: 16px;
    text-align: center;
    justify-content: center;
    white-space: nowrap;
}

  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .settings{
        width: 100vw;
        padding: 8px;
        top: 0;
    }

    .settings-close-icon svg{
        width: 10vw;
        height: 10vh;
    }

    .settings-body p {
        font-size: 18px;
    }
    .settings-body h1 {
        font-size: 26px;
    }
    
    a:link, a:visited {
        font-size: 18px;
      }

      .settings-footer {
        position: fixed;
        bottom: 12px;
   
    }
    .settings-footer h4{
        font-size: 14px;
    }
    .dark-theme .contrast-mode .contact .add-word {
    padding: 0;
    margin: 0;
}
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 601px) and (max-width: 768px) {
    .settings{
        width: 80vw;
    }
    .settings-body p {
        font-size: 20px;
    }
    .settings-body h1 {
        font-size: 26px;
    }
    
    a:link, a:visited {
        font-size: 20px;
      }

      .settings-footer {
        position: fixed;
        bottom: 0;
   
    }
    .settings-footer h4{
        font-size: 16px;
    }
    .dark-theme .contrast-mode .contact .add-word {
    padding: 0;
    margin: 0;
}
  }
  
  /* Medium devices (portrait tablets, 768px and up) */
  @media only screen and (min-width: 769px) and (max-width: 1050px) {
    .settings{
        width: 70vw;
    }
    .settings-body p {
        font-size: 20px;
    }
    .settings-body h1 {
        font-size: 26px;
    }
    
    a:link, a:visited {
        font-size: 20px;
      }
    .settings-footer h4{
        font-size: 16px;
    }
    .dark-theme .contrast-mode .contact .add-word {
        padding: 0;
        margin: 0;
    }
  }