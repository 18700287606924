.shobdleChecker_bg{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-transparent);
    z-index: 3;
}

.shobdleChecker{
    position: fixed;
    width: 80vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: blur(0);
    opacity: 1;
    flex-direction: column;
    background-color: var(--color-bg);
    padding: 2rem;
    border: 1px solid var(--color-border);
    border-radius: 8px;
    z-index: 3;
}

.shobdleChecker-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.shobdleChecker-icon{
    font-size: 100px;
}

.shobdleChecker-title{
    font-family: var(--font-family);
    font-size: 32px;
    margin-bottom: 10px;
    font-weight: 400;
}

.shobdleChecker-button {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #0092D3 ;
    color: #fff;
    font-size: 18px;
    border: none;
    border-radius: 34px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .shobdleChecker-button:hover {
    background-color: #0072BA;
    transform: scale(1.1);
  }

.shobdleChecker-disclaimer{
    margin-top: 50px;
  }

  .shobdleChecker-disclaimer p{
    text-align: center;
    color: #555;
    font-family: var(--font-family);
    font-size: 16px;
  }
