#help-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-transparent);
  z-index: 3;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.help-body {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 80%;
  height: 90%;
  background-color: var(--color-bg);
  padding: 1rem;
  border: 1px solid var(--color-border);
  border-radius: 8px;
  overflow-y: auto;
}

.help-close-icon{
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.help-close-icon svg{
  width: 5vw;
  height: 5vh;
  cursor: pointer;
  color: var(--color-text);
}

#help-container img {
  cursor: auto;
  width: 60%;
  margin-top: -2rem;
}

 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
  .help-body{
    width: 98%;
    height: auto;
  }

  #help-container img {
    margin-top: 0;
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
  #help-container img {
    width: 100%;
  }
}

