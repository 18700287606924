.wordgrid {
    width: 100%;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10vw;
    padding-right: 10vw;
    z-index: 1;
  }
  
  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 5px;
    border: none;
  }

