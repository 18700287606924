.toggle-switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 25px;
  }
  .toggle-switch input[type="checkbox"] {
    display: none;
  }
  .toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 25px;
    display: flex;
    justify-content:flex-start;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
  }
  .toggle-switch .switch::before {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    background-color: #aaa;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
  .toggle-switch input[type="checkbox"]:checked + .switch::before {
    transform: translateX(25px);
      background-color: green;
  }
  .toggle-switch input[type="checkbox"]:checked + .switch {
    background-color: greenyellow;
  }