.key {
    height: 6vh;
    width: 8vw;
    margin: 5px;
    border-radius: 4px;
    display: grid;
    place-items: center;
    font-size: 14px;
    background-color: var(--color-alphabets);
    color: black;
    font-weight: bold;
    font-family: var(--font-family);
    cursor: pointer;
  }

  #big {
    width: 12vw;
    font-size: 18px;
    font-weight: bold;
    background-color: var(--color-border);
  }

  #rprl {
    background-color: var(--color-right-pos-right-letter);
    color: var(--color-text-white);
  }

  #wprl {
    background-color: var(--color-wrong-pos-right-letter);
    color: var(--color-text-white);
  }

  #rpwl {
    background-color: var(--color-right-pos-wrong-letter);
    color: var(--color-text-white);
  }

  #wpwl {
    background-color: var(--color-wrong-pos-wrong-letter);
    color: var(--color-text-white);
  }

  #absent {
    background-color: var(--color-absent-letter);
    color: var(--color-text-white);
  }


@media only screen and (min-width: 601px) and (max-width:767px) and (max-height:800px)  {
  .key{
    height: 6vh;
    width: 7vw;
    font-size: 16px;
  }
  #big {
    font-size: 20px;
    height: 6vh;
    width: 9vw;
  }
}
@media  screen and (min-width: 601px) and (max-width:767px) and (min-height:800px) {
  .key{
    height: 6vh;
    width: 8vw;
    font-size: 20px;
  }
  #big {
    font-size: 26px;
    height: 6vh;
    width: 13vw;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .key{
    height: 7vh;
    width: 6vw;
    font-size: 16px;
  }
  #big {
    font-size: 20px;
    height: 7vh;
    width: 13vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) and (min-height:900px){
  .key{
    height: 7vh;
    width: 9vw;
    font-size: 32px;
  }
  #big {
    font-size: 50px;
    height: 7vh;
    width: 12vw;
  }
}


/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) and (max-width:1200px) and (max-height:1000px){
  .key{
    height: 6vh;
    width: 4vw;
    font-size: 16px;
  }
  #big {
    font-size: 20px;
    height: 6vh;
    width: 8vw;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px){
  .key{
    height: 6vh;
    width: 4vw;
    font-size: 16px;
  }
  #big {
    font-size: 20px;
    height: 6vh;
    width: 8vw;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) and (min-height:1000px) and (max-height:1500px){
  .key{
    height: 6vh;
    width: 4vw;
    font-size: 16px;
  }
  #big {
    font-size: 20px;
    height: 6vh;
    width: 8vw;
  }
}

@media only screen and (min-width: 1800px) and (min-height:1000px) and (max-height:1700px){
  .key{
    font-size: 26px;
  }
  #big {
    font-size: 30px;
  }
}
@media only screen and (min-width: 1800px) and (min-height:1701px){
  .key{
  
    font-size: 26px;
  }
  #big {
    font-size: 30px;
  }
}
