* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background: var(--color-bg);
  overflow-y: auto;
  min-height: 100vh;
}

/* Link to get gradient css code: https://angrytools.com/gradient/ */
