.timer {
    text-align: center;
    font-size: 100px;
    color: rgb(65, 67, 70);
  }
  
  .timer-box {
    display: inline-block;
    width: 100px;
    background-color: rgb(65, 67, 70);
    color: white;
    font-size: 72px;
    padding: 5px;
    border-radius: 12px;
    text-align: center;
  }

  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .timer {
    font-size: 60px;
    }

    .timer-box {
      width: 70px;
      font-size: 42px;
    }
}