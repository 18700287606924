.shobdle__navbar {
    display: flex;
    justify-content: space-between;
    width: calc(100vw- 1rem);
    height: 7vh;
    padding: 0rem 1rem;
    border-bottom: 2px solid #b2beb5;
    z-index: 2;
}


.shobdle__navbar-logos_dc-logo {
    justify-content: flex-start;
    object-fit: cover;
    width: auto;
    height: 100%;
    height: 7vh;
    max-width: 13vw;
 
}

.shobdle__navbar-logos_dc-logo img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    align-items: center;
}

.shobdle__navbar-logos_logo {
    position: fixed;
    height: 100%;
    max-height: 7vh;
    left: 50%;
    top: 0%;
    transform: translate(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.shobdle__navbar-logos_logo p {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 5vh;
    color: var(--color-text);
}

.shobdle__navbar-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: auto;
    max-height: 7vh;
    max-width: 20vw;
}

.shobdle__navbar-menu svg {
    width: 70%;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 0.7rem;
    color: var(--color-text);
}



.shobdle__navbar-phone_menu {
    display: none;
    position: relative;
    align-items: center;
    width: auto;
    height: 100%;
    max-height: 7vh;
   
}

.shobdle__navbar-phone_menu svg {
    cursor: pointer;
    width: auto;
    height: 70%;
    max-height: 7vh;
    color: var(--color-text);
}

.shobdle__navbar-phone_menu_container {
    width: 40vw;
    min-width: 150px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-bg);
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 0.4rem;
    border-radius: 5px;
    border: 1px solid var(--color-border);
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
    z-index: 3;
}

.shobdle__navbar-phone_menu_container p {
    color: var(--color-text);
    font-family: var(--font-family);
    width: 40vw;
    font-weight: 500;
    font-size: 40px;
    font-size: 2vh;
    line-height: 25px;
    border-bottom: 2px solid #b2beb5;
    cursor: pointer;
    min-width: 150px;
    padding: 2em;
    white-space: nowrap;
}


@media screen and (max-width: 1050px) {
    .shobdle__navbar{
        min-height: 50px;
    }
   
    .shobdle__navbar-menu {
        display: none;
    }

    .shobdle__navbar-phone_menu {
        display: flex;
    }
}

@media screen and (min-width: 1051px){
    .shobdle__navbar-logos_dc-logo{
        width: 5vw;

    }
}
