.signin_bg{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-transparent);
    z-index: 3;
}

.signin{
    position: fixed;
    width: 50%;
    height: 60%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: blur(0);
    opacity: 1;
    flex-direction: column;
    background-color: var(--color-bg);
    padding: 2rem;
    border: 1px solid var(--color-border);
    border-radius: 8px;
    z-index: 3;
    overflow-y: auto;
    text-align: center;
}

.signin_body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signin-title{
    font-family: var(--font-family);
    font-size: 32px;
    margin-bottom: 10px;
    font-weight: 400;
}


.signin-close-icon{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.signin-close-icon svg{
    cursor: pointer;
    color: var(--color-text);
    width: 5vw;
    height: 5vh;
    margin: 12px;
}

.signin-input-field {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s ease-in-out;
    text-align: center;
  }
  
  .signin-input-field:focus {
    border-color: #0072ba; 
  }

.anonymous-login-button {
    width: 300px;
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #0092D3 ;
    color: #fff;
    font-size: 18px;
    border: none;
    border-radius: 34px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .anonymous-login-button:hover {
    background-color: #0072BA;
    transform: scale(1.1);
  }



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .signin {
      width: 90vw;
      height: 90vh;
      text-align: center;
    }

    .signin-close-icon svg{
        width: 10vw !important;
        height: 10vh !important;
        margin: 0;
    }

    .anonymous-login-button {
        width: 80vw;
      }
  }