.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    text-align: center;
    overflow: hidden;
  }
  
  .profile-body {
    width: 50vw;
    height: 90vh;
    border: 1px solid #000;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    position: relative; 
  }

  .loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .shobdle_logo{
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 5rem;
  }

  .title {
    font-family: var(--font-family);
    font-size: 1.3rem;
    margin-bottom: 10px;
    font-weight: 400;
    margin-top: 20px;
  }
  
  .profile-input {
    width: 50%;
    padding: 10px;
    margin-top: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s ease-in-out;
    text-align: center;
  }
  
  .profile-input:focus {
    border-color: #0072ba; 
  }

  .profile-input-field {
    flex: 1;
    width: 50px;
    padding: 12px;
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin: 0 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }

  .profile-input-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .start-button {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #0092D3 ;
    color: #fff;
    font-size: 18px;
    border: none;
    border-radius: 34px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .start-button:hover {
    background-color: #0072BA;
    transform: scale(1.1);
  }

  .error-message {
    color: red;
    margin-top: 8px;
  }

  .profile-disclaimer p{
    margin-top: 50px;
    text-align: center;
    color: #555;
    font-family: var(--font-family);
    font-size: 16px;
  }

  .profile-table-title {
    font-family: var(--font-family);
    font-size: 1.3rem;
    margin-bottom: 10px;
    font-weight: 400;
    margin-top: 100px;
  }

  .profile-table{
    width: 70%;
    font-family: var(--font-family);
    border: solid 1px #ccc;
  }

  .home-button {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #0092D3 ;
    color: #fff;
    font-size: 18px;
    border: none;
    border-radius: 34px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .home-button:hover {
    background-color: #0072BA;
    transform: scale(1.1);
  }

  #snackbar {
    visibility: hidden; 
    min-width: 250px;
    margin-left: -125px; 
    background-color: #333; 
    color: #fff; 
    text-align: center; 
    border-radius: 2px; 
    padding: 16px; 
    position: fixed; 
    z-index: 5; 
    left: 50%; 
    bottom: 20%; 
  }
  
  #snackbar.show {
    visibility: visible; 
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .profile-body {
        width: 90vw;
      }

      .profile-input{
        width: 90%;
      }

      .profile-table{
        width: 100%;
      }
  }

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
    .profile-body {
      width: 80vw;
    }
  }