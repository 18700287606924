@font-face {
    font-family: 'Alinur';
    src: url('../../assets/fonts/alinur.ttf') format('truetype'); /* Ensure correct path */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Mahfuz';
    src: url('../../assets/fonts/mahfuz.ttf') format('truetype'); /* Ensure correct path */
    font-weight: normal;
    font-style: normal;
}


.history_bg{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-transparent);
    z-index: 3;
}

.history{
    position: fixed;
    width: 80vw;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url('../../../public/history-bg.png'); 
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat;
    filter: blur(0);
    opacity: 1;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border: 1px solid var(--color-border);
    border-radius: 8px;
    z-index: 3;
    overflow-y: auto;
    text-align: center;
}
.history-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.history-close-icon{
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
}
.history-close-icon svg{
    width: 5vw;
    height: 5vh;
    cursor: pointer;
    color: var(--color-text);
}


.history-body .name {
    font-family: 'Alinur', sans-serif;
    font-weight: 400;
    font-size: 58px;
    color: var(--color-blue);
    justify-content: center;
    text-align: center;
    margin-top: 8px;
}

.history-body .sub-text {
    font-family: 'Mahfuz', sans-serif;
    font-weight: 200;
    font-size: 26px;
    color: var(--color-blue);
    justify-content: center;
    text-align: center;
}

.history-body .bar{
    width: 60%;
    height: 1px;
    background-color: var(--color-blue);
    opacity: 0.5;
    margin-top: 5px;
}

/* .history-win {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
}

.history-win p{
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    color: var(--color-text);
    justify-content: center;
    text-align: center;
    margin-right: 8px;
}

.history-win .container {
    position: relative;
    width: 130px;
    height: 130px;
    background: url('../../assets/element.svg') center/cover no-repeat;
  }
  
  .history-win .text-overlay {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 32px;
    color: var(--color-text);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: flex;
    flex-direction: row;
  }

  .history-win .text-overlay p{
    font-size: 20px;
    align-self: flex-end;
  } */


.stats{
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
}

.stats-row{
    display: flex;
    flex-direction: row;
}

.played{
    display: flex;
    flex-direction: column;
    margin: 1rem;
    width: 70px;
}


.currStreak{
    display: flex;
    flex-direction: column;
    margin: 1rem;
    width: 70px;
}

.maxStreak{
    display: flex;
    flex-direction: column;
    margin: 1rem;
    width: 70px;
}

.history-disclaimer p{
    margin-top: 20px;
    text-align: center;
    color: var(--color-blue);
    font-family: 'Mahfuz', sans-serif;
    font-size: 18px;
    font-weight: 300;
  }

  .share-button {
    width: 150px;
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #0092D3 ;
    color: #fff;
    font-size: 18px;
    border: none;
    border-radius: 34px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .share-button:hover {
    background-color: #0072BA;
    transform: scale(1.1);
  }
  

.stats p {
    font-family: 'Mahfuz', sans-serif;
    color: var(--color-blue);
    font-weight: 200;
    font-size: 18px;
    text-align: center;
    justify-content: center;
    white-space: nowrap;
}

.stats h2 {
    font-family: 'Mahfuz', sans-serif;
    color: var(--color-blue);
    font-weight: 400;
    font-size: 36px;
    text-align: center;
    justify-content: center;
    white-space: nowrap;
    border-bottom: 1px solid var(--color-blue);
    margin-bottom: 5px;
}

.history-result{
    font-size: 42px;
}

.history-table-title {
    font-family: 'Mahfuz', sans-serif;
    font-size: 20px;
    margin-bottom: 12px;
    font-weight: 600;
    margin-top: 100px;
    color: var(--color-blue);
  }

  .history-table{
    width: 70%;
    font-family: var(--font-family);
    border: solid 1px #ccc;
    margin-top: 8px;
  }

  .history-home-button {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #0092D3 ;
    color: #fff;
    font-size: 18px;
    border: none;
    border-radius: 34px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    margin-bottom: 20px;
  }
  
  .history-home-button:hover {
    background-color: #0072BA;
    transform: scale(1.1);
  }

#history-snackbar {
    visibility: hidden; 
    min-width: 250px;
    margin-left: -125px; 
    background-color: #333; 
    color: #fff; 
    text-align: center; 
    border-radius: 2px; 
    padding: 16px; 
    position: fixed; 
    z-index: 5; 
    left: 50%; 
    bottom: 20%; 
  }
  
  #history-snackbar.show {
    visibility: visible; 
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }

 /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .history{
        width: 90vw;
        padding: 12px;
    }

    .history-close-icon svg{
        width: 7vw;
        height: 7vh;
    
    }

    .history-body .name {
        font-size: 22px;
    }

    .history-body .sub-text {
        font-size: 20px;
    }

    .history-table{
        width: 100%;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 601px) and (max-width: 768px) {
    .history{
        width: 90vw;
    }

    .history-body .name {
        font-size: 22px;
    }

    .history-body .sub-text {
        font-size: 20px;
    }
  }