@font-face {
  font-family: 'Mahfuz';
  src: url('../../assets/fonts/mahfuz.ttf') format('truetype'); /* Ensure correct path */
  font-weight: normal;
  font-style: normal;
}


.coupon-container {
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Align content at the bottom */
  position: relative;
  margin-top: 10px;
  width: 800px; /* Adjust as needed */
  height: 190px; /* Adjust as needed */
  background-image: url('../../assets/coupon.svg'); /* Corrected Path */
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
}

/* Wrapper for code and date */
.coupon-info {
  position: absolute;
  right: 200px;
  bottom: 60px; /* Adjust position */
  left: 50% ;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: end;
}

/* Style for the coupon code */
.coupon-code, .coupon-date {
  font-family: 'Mahfuz', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: white;
  text-align: center;
  margin: 3px 0;
  background: var(--color-blue-opacity);
  padding: 0 8px;
  border-radius: 12px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}  

.download-button {
  width: 200px;
  padding: 10px 20px;
  background-color: #0092D3 ;
  color: #fff;
  font-size: 18px;
  border: none;
  border-radius: 34px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.download-button:hover {
  background-color: #0072BA;
  transform: scale(1.1);
}


/* ✅ Responsive Styles for Tablet (max-width: 768px) */
@media screen and (max-width: 768px) {
  .coupon-container {
    width: 600px; /* Adjust width for tablets */
    height: 160px; /* Reduce height */
  }

  .coupon-info {
    right: 145px;
    bottom: 45px;
  }

  .coupon-code, .coupon-date {
    font-size: 10px;
  }
}

/* ✅ Responsive Styles for Mobile Devices (max-width: 480px) */
@media screen and (max-width: 480px) {
  .coupon-container {
    width: 400px; /* Make it fit mobile screen */
    height: 140px; /* Reduce height further */
  }

  .coupon-info {
    right: 100px;
    bottom: 45px;
  }

  .coupon-code, .coupon-date {
    font-size: 7px; /* Smaller text for mobile */
  }
}




;



































