.keyboard {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  z-index: 1;
  }
  
  .line1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .line2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .line3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .line4 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
  
    .keyboard {
      margin-top: 0;
    }
  }