  .home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
  }
  
  .home-body {
    width: 50%;
    height: 60%;
    border: 1px solid #000;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    text-align: center;
  }

  .shobdle_logo{
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 5rem;
  }

  .title {
    font-family: var(--font-family);
    font-size: 1.5rem;
    margin-bottom: 10px;
    font-weight: 400;
  }
  
  .input-field {
    flex: 1;
    width: 50px;
    padding: 12px;
    margin: 0 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
  }

  .input-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .create-button {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #0092D3 ;
    color: #fff;
    font-size: 18px;
    border: none;
    border-radius: 34px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .create-button:hover {
    background-color: #0072BA;
    transform: scale(1.1);
  }

  .error-message {
    color: red;
    margin-top: 8px;
  }

  .my-account-link {
    font-family: var(--font-family);
    margin-top: 12px;
    font-size: 14px;
    color: #0072BA;
    cursor: pointer;
  }

  .disclaimer{
    width: 80%;
    margin-top: 100px;
  }

  .disclaimer p{
    text-align: center;
    color: #555;
    font-family: var(--font-family);
    font-size: 16px;
  }

  #snackbar {
    visibility: hidden; 
    min-width: 250px;
    margin-left: -125px; 
    background-color: #333; 
    color: #fff; 
    text-align: center; 
    border-radius: 2px; 
    padding: 16px; 
    position: fixed; 
    z-index: 5; 
    left: 50%; 
    bottom: 20%; 
  }
  
  #snackbar.show {
    visibility: visible; 
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
  
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #f8f9fa; /* Google Gray Background color */
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1);
  }
  
  .footer-image {
    width: 200px;
    margin-bottom: 10px;
  }
  
  .footer-text {
    font-size: 0.8rem;
    color: #555;
    margin: 0;
  }

.footer a,
.footer a:link,
.footer a:visited,
.footer a:hover,
.footer a:active {
text-decoration: none;
color: inherit;
border: none;
background-color: transparent;
display: inline-block;
outline: none;
}

.footer a,
.footer a:link,
.footer a:visited {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .home{
    justify-content: flex-start;
    padding-top: 20px;
  }
  .home-body {
    width: 90vw;
    height: 80vh;
  }

  .disclaimer{
    width: 100%;
  }

  .footer{
    height: 15vh;
  }

  .footer-image {
    margin-bottom: 0;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .home-body {
    width: 80vw;
    height: 60vh;
  }

  .footer{
    height: 15vh;
  }

  .footer-image {
    margin-bottom: 0;
  }
}

/* Medium devices (portrait tablets, 768px and up) */
@media only screen and (min-width: 769px) and (max-width: 991px) and (orientation: portrait) {
  .home-body {
    width: 70%;
    height: 60%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .home-body {
    width: 50%;
    height: 60%;
  }
}


