@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Bengali:wght@100;200;300;400;500;600;700;800;900&family=Noto+Serif+Bengali:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --font-family: "Noto Sans Bengali", sans-serif;
  
  --color-bg: #ffffff;
  --color-text: #000000;
  --color-text-white: #ffffff;
  --color-subtext: #5E7D7E;
  --color-border: #d3d3d3;
  --color-alphabets: #c0c0c0;
  --color-transparent: rgb(0,0,0,.7);
  --color-blue: #0072ba;
  --color-blue-dark: #0e4f95;
  --color-blue-opacity: rgba(0, 146, 211);
  --color-dark-grey: #a9a9a9;
  --color-right-pos-right-letter: rgb(122, 187, 122);
  --color-wrong-pos-right-letter: rgb(202, 189, 67);
  --color-right-pos-wrong-letter: rgb(116, 188, 236);
  --color-wrong-pos-wrong-letter: rgb(224, 148, 224);
  --color-absent-letter: rgb(90, 90, 90);
  --color-help-container: rgb(0, 0, 0, 0.5);

}

[data-theme='dark'] {

  --font-family: "Noto Sans Bengali", sans-serif;
  
  --color-bg: #000000;
  --color-text: #ffffff;
  --color-text-white: #ffffff;
  --color-subtext: #5E7D7E;
  --color-border: #d3d3d3;
  --color-alphabets: #c0c0c0;
  --color-transparent: rgb(0,0,0,.7);
  --color-blue: #0072ba;
  --color-dark-grey: #a9a9a9;
  --color-right-pos-right-letter: rgb(122, 187, 122);
  --color-wrong-pos-right-letter: rgb(202, 189, 67);
  --color-right-pos-wrong-letter: rgb(116, 188, 236);
  --color-wrong-pos-wrong-letter: rgb(224, 148, 224);
  --color-absent-letter: rgb(90, 90, 90);
  --color-help-container: rgb(255, 255, 255, 0.2);
}

[data-contrast='contrast'] {
  --color-right-pos-right-letter: #b19cd9;
  --color-wrong-pos-right-letter: #5b3b8c;
  --color-right-pos-wrong-letter: #ffff00;
  --color-wrong-pos-wrong-letter: #b8b800;
  --color-absent-letter: rgb(90, 90, 90);

}