.game {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    background: var(--color-bg);
  }

.shobdle__bg {
    width: 100vw;
    height: 100vh;
    background: var(--color-bg);
    touch-action: manipulation;
  }
  .shobdle_body {
    position: fixed;
    width: 100vw;
    top: 9%;
    height: 93%;
    left: 50%;
    transform: translate(-50%);
    background-color: var(--color-bg);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0;
  }
  
  #snackbar {
    visibility: hidden; 
    min-width: 250px;
    margin-left: -125px; 
    background-color: #333; 
    color: #fff; 
    text-align: center; 
    border-radius: 2px; 
    padding: 16px; 
    position: fixed; 
    z-index: 5; 
    left: 50%; 
    bottom: 20%; 
    z-index: 5;
  }
  
  #snackbar.show {
    visibility: visible; 
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
  
  /* Animations to fade the snackbar in and out */
  @-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 20%; opacity: 1;}
  }
  
  @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 20%; opacity: 1;}
  }
  
  @-webkit-keyframes fadeout {
    from {bottom: 20%; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }
  
  @keyframes fadeout {
    from {bottom: 20%; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }
  
  
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
  
    .shobdle_body {
      width: 100vw;
      top: 6vh;
      height: 90%;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 601px) and (max-width:767px) {
    .shobdle_body {
      width: 100vw;
    }
  }
  
  @media only screen and (min-width: 601px) and (max-width:767px) and (max-height:800px) {
    .shobdle_body {
      width: 80vw;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .shobdle_body {
      width: 80vw;
    }
    #snackbar{
      width: 30vw;
      min-height: 100px;
      font-size: 30px;
    }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) and (max-width:1199px){
    .shobdle_body{
      width: 50vw;
    }
  }
  @media only screen and (min-width: 992px) and (max-width:1199px) and (min-height: 1000px){
    .shobdle_body{
      width: 100vw;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px){
    .shobdle_body{
      width: 50vw;
    }
  }